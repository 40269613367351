import {actions} from './actions';

const Reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_USER_DATA:
      return {
        ...state,
        uid: action.uid,
        user: action.user,
        charityOverride: undefined,
      }
    case actions.SET_USER_TOKEN:
      return {
        ...state,
        token: action.token,
      }
    case actions.SET_CHARITY_DATA:
      return {
        ...state,
        cid: action.cid,
        charity: action.charity,
      }
    case actions.SET_CHARITY_OVERRIDE:
      return {
        ...state,
        cid: undefined,
        charity: undefined,
        charityOverride: action.charity,
      }
    case actions.REMOVE_CHARITY_OVERRIDE:
      return {
        ...state,
        cid: undefined,
        charity: undefined,
        charityOverride: undefined,
      }
    default: return state;
  }
}

export default Reducer;