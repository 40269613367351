import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useUser } from 'reactfire';
import NotFound from './NotFound'

import { Context } from '../store';

// Routes
import { DefaultLayout, titleTemplate, defaultRoute, routes } from '../routes'

// ---
// Main route function

const updatedRoutes = routes.map(route => {
  route.layout = route.layout || DefaultLayout;
  route.exact = typeof route.exact === 'undefined' ? true : route.exact;
  route.private = typeof route.private === 'undefined' ? true : route.private; // default to private
  return route;
})

// Set app loading class
document.documentElement.classList.add('app-loading')

function Router(props) {
  const routes = updatedRoutes;
  const [state, ] = useContext(Context);
  const { data: user } = useUser();

  useEffect(() => {
    const removeLoadingClass = () => {
      document.documentElement.classList.remove('app-loading')
    }
    // Remove splash screen
    const splashScreen = document.querySelector('.app-splash-screen')
    if (splashScreen) {
      splashScreen.style.opacity = 0
      setTimeout(() => {
        if (splashScreen && splashScreen.parentNode) {
          splashScreen.parentNode.removeChild(splashScreen)
        }
        removeLoadingClass()
      }, 300)
    } else {
      removeLoadingClass()
    }
  }, []);

  const setTitle = (title) => {
    document.title = titleTemplate.replace('%s', title)
  }

  const scrollTop = (to, duration, element = document.scrollingElement || document.documentElement) => {
    if (element.scrollTop === to) return
    const start = element.scrollTop
    const change = to - start
    const startDate = +new Date()

    if (!duration) {
      element.scrollTop = to
      return
    }

    // t = current time; b = start value; c = change in value; d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2
      if (t < 1) return c / 2 * t * t + b
      t--
      return -c / 2 * (t * (t - 2) - 1) + b
    }

    const animateScroll = () => {
      const currentDate = +new Date()
      const currentTime = currentDate - startDate
      element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll)
      } else {
        element.scrollTop = to
      }
    }

    animateScroll()
  }
  
  const logout = props.logout;

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        {routes.map(route => {
          return <Route
            path={route.path}
            exact={route.exact}
            render={props => {
              if (route.private && !user) {
                return <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: props.location }
                  }}
                />
              }
              if (route.path === "/login" && user && state.uid) {
                return <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              } 

              // On small screens collapse sidenav
              if (window.layoutHelpers && window.layoutHelpers.isSmallScreen()) {
                window.layoutHelpers.setCollapsed(true, false)
              }

              // Scroll page to top on route render
              scrollTop(0, 0);
              
              // Set title if available
              if (route.title) {
                setTitle(route.title);
              }

              // Return layout
              return <route.layout {...props} logout={logout}>
                <route.component {...props} title={route.title} setTitle={setTitle} scrollTop={scrollTop} />
              </route.layout>
            }}
            key={route.path}
          />
        })}
        {defaultRoute !== '/' && <Redirect from="/" to={defaultRoute} exact={true} />}

        {/* NotFound page */}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router;
