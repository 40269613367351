import React, { useContext, useEffect, useState } from 'react';
import Router from './shared/Router';

import { useAuth, useFirestore } from 'reactfire';
import { docData } from 'rxfire/firestore';
import { Settings as LuxonSettings } from "luxon";

import { Context, actions } from './store';
import Loader from './shared/Loader';

import './vendor/styles/bootstrap-material.scss'
import './vendor/styles/appwork-material.scss'
// import './vendor/styles/theme-paper-material.scss'
import './theme/styles/dp-paper-material.scss'
import './vendor/styles/colors-material.scss'
import './vendor/styles/uikit.scss'
import './App.scss'

function App(props) {
  const [state, dispatch] = useContext(Context);

  const auth = useAuth();
  const firestore = useFirestore();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  // Note, managing user subscription manually due to a bug in useUser()
  // https://github.com/FirebaseExtended/reactfire/issues/342
  useEffect(() => {
    console.log('Loading initial user state.');
    let unsubscribe = auth.onIdTokenChanged((user) => {
      // Note: this fires on signin, signout, and token update
      setLoading(false);
      setUser(user);
      // Note: Should this happend before setLoading/User and delay that?
      user?.getIdTokenResult(true).then((token) => {
        console.log('Received updated id token: ', token);
        dispatch({
          type: actions.SET_USER_TOKEN,
          token,
        });
      });
      console.log('Received updated user: ', user);
    }, (e) => {
      setError(e);
      console.error(e);
    });
    return () => unsubscribe();
  }, [auth, dispatch]);

  const uid = user?.uid;
  useEffect(() => {
    if (!firestore || !uid) {
      dispatch({
        type: actions.SET_USER_DATA,
        uid: null,
        user: {},
      })
      return;
    }
    console.log('Subscribing to user data.');
    const ref = firestore.doc(`users/${uid}`);
    const subscription = docData(ref).subscribe((userData) => {
      console.log('Received updated user data: ', userData);
      dispatch({
        type: actions.SET_USER_DATA,
        uid,
        user: userData,
      });
    }, console.error);
    return () => subscription.unsubscribe();
  }, [dispatch, firestore, uid]);

  const charityId = state.charityOverride?.id || state.token?.claims?.charities?.[0];
  useEffect(() => {
    if (!firestore || !charityId) {
      dispatch({
        type: actions.SET_CHARITY_DATA,
        cid: null,
        charity: {
          name: state.token?.claims ? 'No charity associated with this account' : undefined,
        },
      })
      return;
    }
    console.log('Subscribing to charity data.');
    const subscription = docData(firestore.doc(`charities/${charityId}`)).subscribe((charityData) => {
      console.log('Received updated charity data: ', charityData);
      // Update moment timzeone settings
      if (charityData.timezone) {
        LuxonSettings.defaultZoneName = charityData.timezone;
      } else {
        LuxonSettings.defaultZoneName = 'local';
      }
      dispatch({
        type: actions.SET_CHARITY_DATA,
        cid: charityId,
        charity: charityData,
      });
    }, console.error);
    return () => subscription.unsubscribe();
  }, [dispatch, firestore, charityId, state.token?.claims]);

  const logout = async (e) => {
    e.preventDefault();
    await auth.signOut();
  }

  if (error) {
    return <div>Something went wrong... please reload the site and try again.</div>;
  }

  if (loading) {
    return <Loader />
  }

  return <Router logout={logout} />;
}

export default App;