import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'

// Layouts
import Layout1 from './shared/layouts/Layout1'
import LayoutBlank from './shared/layouts/LayoutBlank'

// Lazy load component
const lazy = (cb: any) => loadable<any>(() => pMinDelay(cb(), 200), { fallback: <Loader /> })

// ---
// Default application layout

export const DefaultLayout = Layout1

// ---
// Document title template

export const titleTemplate = '%s - Donation Point Portal'

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object


export const defaultRoute = '/'
const namedRoutes = {
  home: {
    title: 'Home',
    path: '/',
    component: lazy(() => import('./components/Home'))
  },
  login: {
    title: 'Login',
    path: '/login',
    layout: LayoutBlank,
    private: false,
    component: lazy(() => import('./components/Login')),
  },
  // User
  changePassword: {
    title: 'Change Password',
    path: '/change-password',
    component: lazy(() => import('./components/user/ChangePassword')),
  },
  // Charity
  charityDetails: {
    title: 'Charity Details',
    path: '/charity/details',
    component: lazy(() => import('./components/charity/CharityDetails')),
  },
  charityPrivateDetails: {
    title: 'Private Charity Details',
    path: '/charity/private-details',
    component: lazy(() => import('./components/charity/PrivateDetails')),
  },
  // Go
  goCampaigns: {
    title: 'Go Campaigns',
    path: '/go/campaigns',
    component: lazy(() => import('./components/go/Campaigns')),
  },
  addGoCampaign: {
    title: 'Create Go Campaign',
    path: '/go/campaigns/new',
    component: lazy(() => import('./components/go/AddCampaign')),
  },
  editGoCampaign: {
    title: 'Edit Go Campaign',
    path: '/go/campaigns/:id',
    component: lazy(() => import('./components/go/EditCampaign')),
  },
  // Kiosk
  kioskCampaigns: {
    title: 'Event Campaigns',
    path: '/event/campaigns',
    component: lazy(() => import('./components/kiosk/Campaigns')),
  },
  addKioskCampaign: {
    title: 'Create Event Campaign',
    path: '/event/campaigns/new',
    component: lazy(() => import('./components/kiosk/AddCampaign')),
  },
  editKioskCampaign: {
    title: 'Event Campaign',
    path: '/event/campaigns/:id',
    component: lazy(() => import('./components/kiosk/EditCampaign')),
  },
  kiosks: {
    title: 'Event Tablets',
    path: '/event/tablets',
    component: lazy(() => import('./components/kiosk/Kiosks')),
  },
  // Admin
  listCharities: {
    title: 'Charities',
    path: '/admin/charities',
    component: lazy(() => import('./components/admin/Charities')),
  },
  listUsers: {
    title: 'Users',
    path: '/admin/users',
    component: lazy(() => import('./components/admin/Users')),
  },
  addUser: {
    title: 'Create User',
    path: '/admin/user/new',
    component: lazy(() => import('./components/admin/AddUser'))
  },
}

export const routes = Object.values(namedRoutes);

interface StringMap { [key: string]: string; }
export const getPath = (routeName: keyof typeof namedRoutes, args: StringMap = {}) => {
  return namedRoutes[routeName].path.replace(/:([^/:]+)/g, (_m: any, arg: string) => args[arg]);
}


// let routeNames: {[key: string]: (args?: {[key: string]: string}) => string} = {};
// routes.forEach(route => {
//   if (route.name) {
//     routeNames[route.name] = (args = {}) => {
//       let path = route.path;
//       Object.keys(args).forEach(key => path = path.replace(`:${key}`, args[key]));
//       return path;
//     }
//   }
// });
// export const namedRoutes = Object.freeze({ ...routeNames });