import React from 'react';

export default (<div className="sk-cube-grid">
  <div className="sk-cube sk-cube1 spinner-bg"></div>
  <div className="sk-cube sk-cube2 spinner-bg"></div>
  <div className="sk-cube sk-cube3 spinner-bg"></div>
  <div className="sk-cube sk-cube4 spinner-bg"></div>
  <div className="sk-cube sk-cube5 spinner-bg"></div>
  <div className="sk-cube sk-cube6 spinner-bg"></div>
  <div className="sk-cube sk-cube7 spinner-bg"></div>
  <div className="sk-cube sk-cube8 spinner-bg"></div>
  <div className="sk-cube sk-cube9 spinner-bg"></div>
</div>);