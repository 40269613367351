import React, { Component } from 'react'
import { footerBg } from './Config';

class LayoutFooter extends Component {
  render() {
    return (
      <nav className={`layout-footer footer bg-${footerBg}`}>
        <div className="container-fluid container-p-x pb-3">
          Donation Point &copy; <a href="https://questps.com.au/" className="footer-link pt-3">Quest Payment Systems Pty. Ltd.</a>
        </div>
      </nav>
    )
  }
}

export default LayoutFooter;
