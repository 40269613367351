import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import Popper from 'popper.js'
import App from './App'
import Store from './store';
import * as serviceWorker from './serviceWorker'
import { firebaseConfig } from './config';

import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { FirebaseAppProvider } from 'reactfire';

import { Toaster } from 'react-hot-toast';

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

const rootElement = document.getElementById('root')
ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Store>
      <App />
      <Toaster toastOptions={{
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
          padding: '1rem',
        },
      }} />
    </Store>
  </FirebaseAppProvider>,
  rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
