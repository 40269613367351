import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { NavLink, useHistory } from 'react-router-dom'
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import layoutHelpers from './helpers';
import { navbarBg } from './Config';
import { Context, actions } from '../../store';
import { getPath } from '../../routes';

function LayoutNavbar (props) {
  const [state, dispatch] = useContext(Context);
  const history = useHistory();

  const clearCurrentCharity = () => {
    dispatch({
      type: actions.REMOVE_CHARITY_OVERRIDE,
    });
    history.push(getPath("home"));
  }
  
  const toggleSidenav = function(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  const logout = async (e) => {
    await props.logout(e);
    history.push(getPath("home"));
  }

  return (
    <Navbar bg={navbarBg} expand="lg" className="layout-navbar align-items-lg-center container-p-x">

      {/* Brand */}
      <Navbar.Brand as={NavLink} to="/"><img src="/favicon.png" alt="Donation Point" />Donation Point</Navbar.Brand>

      {/* Sidenav toggle */}
      {props.sidenavToggle && (
        <Nav className="align-items-lg-center mr-auto mr-lg-4">
          <Nav.Item as="a" className="nav-item nav-link px-0 ml-2 ml-lg-0" href="#toggle" onClick={toggleSidenav}>
            <i className="fas fa-bars text-large align-middle"></i>
          </Nav.Item>
        </Nav>
      )}

      {/* Navbar toggle */}
      <Navbar.Toggle />

      <Navbar.Collapse>
        <Nav className="align-items-lg-center mr-auto">
          <Nav.Item>
            <b>{state.charity?.name || "..."}{state.charityOverride && <> (as Quest admin <button className="btn btn-link p-0" onClick={clearCurrentCharity}><i className="fas fa-fw fa-times-circle"></i></button>)</>}</b>
          </Nav.Item>
        </Nav>
        <Nav>
          <Dropdown as={Nav.Item} alignRight={true}>
            <Dropdown.Toggle as={Nav.Link}><i className={`fas fa-fw fa-user${state.token?.claims?.admin && '-lock'} mr-2`}></i>{state.user?.name || state.user?.email || "Unknown"}</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item as={NavLink} to={getPath('changePassword')} activeClassName=""><i className="fas fa-fw fa-lock mr-2 text-info"></i> Change Password</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={logout}><i className="fas fa-fw fa-sign-out-alt mr-2 text-danger"></i> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  )
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

export default LayoutNavbar;
