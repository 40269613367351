import React, { useContext } from 'react'
import Sidenav from '../../vendor/libs/sidenav'
import { sidenavBg } from './Config';
import { Context } from '../../store';

import { getPath } from '../../routes';

function LayoutSidenav({ orientation = 'vertical', location }) {
  const [state, ] = useContext(Context);

  function layoutSidenavClasses() {
    let bg = sidenavBg

    if (orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
        .replace(' sidenav-dark', '')
        .replace(' sidenav-light', '')
        .replace('-darker', '')
        .replace('-dark', '')
    }

    return `bg-${bg} ` + (
      orientation !== 'horizontal'
        ? 'layout-sidenav'
        : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  function dpgoAccess() {
    return state.token?.claims?.admin || state.token?.claims?.access?.includes('dpgo');
  }

  function kioskAccess() {
    return state.token?.claims?.admin || state.token?.claims?.access?.includes('dpevent');
  }

  return (
    <Sidenav orientation={orientation} className={layoutSidenavClasses()}>
      {/* Inner */}
      <div className={`sidenav-inner ${orientation !== 'horizontal' ? 'py-1' : ''}`}>
        <Sidenav.RouterLink to={getPath("home")} exact={true} icon="fas fa-fw fa-home">Home</Sidenav.RouterLink>
        <Sidenav.RouterLink to={getPath("charityDetails")} exact={true} icon="fas fa-fw fa-heart">Charity Details</Sidenav.RouterLink>
        {state.token?.claims?.admin &&
          <Sidenav.RouterLink to={getPath("charityPrivateDetails")} exact={true} icon="fas fa-fw fa-lock">Private Charity Details</Sidenav.RouterLink>}
        {dpgoAccess() && <>
          <Sidenav.Divider />
          <Sidenav.Header className="small font-weight-semibold">DONATION POINT GO</Sidenav.Header>
          <Sidenav.RouterLink to={getPath("goCampaigns")} exact={false} icon="fas fa-fw fa-tools">Go Campaigns</Sidenav.RouterLink>
          <Sidenav.Link href="https://donationpointgo.com/qr-code-guide.html" target="_blank" icon="fas fa-fw fa-question-circle">QR Code Guide <i className="fas fa-fw fa-external-link-alt"></i></Sidenav.Link>
        </>}
        {kioskAccess() && <>
          <Sidenav.Divider />
          <Sidenav.Header className="small font-weight-semibold">DONATION POINT EVENT</Sidenav.Header>
          <Sidenav.RouterLink to={getPath("kioskCampaigns")} exact={false} icon="fas fa-fw fa-tools">Event Campaigns</Sidenav.RouterLink>
          <Sidenav.RouterLink to={getPath("kiosks")} exact={false} icon="fas fa-fw fa-desktop">Event Tablets</Sidenav.RouterLink>
        </>}
        {state.token?.claims?.admin && <>
          <Sidenav.Divider />
          <Sidenav.Header className="small font-weight-semibold">ADMIN TOOLS</Sidenav.Header>
          <Sidenav.RouterLink to={getPath("listCharities")} exact={true} icon="fas fa-fw fa-heart">Charities</Sidenav.RouterLink>
          <Sidenav.RouterLink to={getPath("listUsers")} exact={true} icon="fas fa-fw fa-users">Users</Sidenav.RouterLink>
        </>}
        <Sidenav.Divider />
        <Sidenav.Link href="https://help.portal.donationpoint.app/portal" target="_blank" icon="fas fa-fw fa-question-circle">Portal Help <i className="fas fa-fw fa-external-link-alt"></i></Sidenav.Link>
        
      </div>
    </Sidenav>
  )
}

export default LayoutSidenav;
