// WARNING
// Do NOT put any sensitive data in this file - it is ALL exposed on the client!!

let _domain = `staging.dpgo.io`;
let _baseFunctionURL = `https://us-central1-donation-point-dev.cloudfunctions.net`;
let _defaultAppleMerchantId = 'merchant.io.pmnts.sandbox.applepay.quest-test';
let _firebaseConfig = {
  apiKey: "AIzaSyBKhUt1ZjNQt2tHdpaHOcFa7bsiim9dv-I",
  authDomain: "donation-point-dev.firebaseapp.com",
  databaseURL: "https://donation-point-dev.firebaseio.com",
  projectId: "donation-point-dev",
  storageBucket: "donation-point-dev.appspot.com",
  messagingSenderId: "579013162937",
  appId: "1:579013162937:web:53d592d45a741952116868"
};
if (process.env.REACT_APP_MODE === 'production') {
  _domain = `dpgo.io`;
  _defaultAppleMerchantId = 'merchant.io.pmnts.production.applepay.quest-paymentsystems';
  _baseFunctionURL = `https://us-central1-donation-point.cloudfunctions.net`;  
  _firebaseConfig = {
    apiKey: "AIzaSyBFpWhK5C5LmTQASd4vlexhMPqOpZkafSg",
    authDomain: "donation-point.firebaseapp.com",
    databaseURL: "https://donation-point.firebaseio.com",
    projectId: "donation-point",
    storageBucket: "donation-point.appspot.com",
    messagingSenderId: "468360965618",
    appId: "1:468360965618:web:80259ba108b08c04c80f6b"
  };
}

export const domain = _domain;
export const baseFunctionURL = _baseFunctionURL;
export const defaultAppleMerchantId = _defaultAppleMerchantId;
export const firebaseConfig = _firebaseConfig;