import React, {createContext, useReducer} from "react";
import Reducer from './Reducer';
import firebase from "firebase/app";
import { Charity } from '../types';

interface MainState {
    uid: string|null,
    cid: string|null,
    user: {[key: string]: any},
    charity: Partial<Charity>,
    token: firebase.auth.IdTokenResult|null,
}

const initialState: MainState = {
  uid: null,
  cid: null,
  user: {},
  charity: {},
  token: null,
};

const Store = ({children}: {children: React.ReactElement}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};
export const Context = createContext<[MainState, React.Dispatch<any>]>([initialState, () => null]);
export * from './actions';
export default Store;